import { useState } from "react";
import GoButton from "../../components/button";
import GoInputField from "../../components/input-field";
import { AuthState } from "../../container/AuthContainer";
import { AuthService } from "../../utils/Api";
import ApiEndpoints from "../../utils/ApiEndpoints";
import styles from "./index.module.scss";

interface LoginScreenProps {
  changeScreen: (screen: AuthState,email:string) => void;
}
let emailRegex = new RegExp("[a-z0-9]+@[a-z]+.[a-z]{2,3}");

export default function LoginScreen({ changeScreen }: LoginScreenProps) {
  const [email, setEmail] = useState("");
  const [errorMsg, seterrorMsg] = useState("");
  // const dispatch = useDispatch();
  const onSignIn = () => {
    if (!email) {
      seterrorMsg("Please enter email");
    } else if (!emailRegex.test(email)) {
      seterrorMsg("Please enter valid email");
    } else {
      AuthService.post(ApiEndpoints.auth.generateOtp, { email })
        .then(({ status, data }:any) => {
          if (status === 200) {
            // dispatch(setLoginData({ ...data, email }));
            changeScreen(AuthState.ENTER_OTP_SCREEN,email);
          } else {
            seterrorMsg(data.message);
          }
        })
        .catch((err:any) => {
          seterrorMsg(err.message);
        });
    }
  };

  const keyPressHandler = (event:any) => {
    if (event.key === "Enter" || event.keyCode === 13) {
      onSignIn()
    }
  }
  return (
    <div className={styles.mainContainer}>
      <div className={styles.top}>
        <div className={styles.heading}>Sign in</div>

        <div className={styles.inputContainer}>
          <GoInputField
            label="Email"
            value={email}
            onChange={setEmail}
            errorMsg={errorMsg}
            placeholder="Enter your email"
            onKeyPress={keyPressHandler}
          />
        </div>

        <div className={styles.signInButton}>
          <GoButton onClickHandler={onSignIn} title="SIGN IN" />
        </div>
      </div>

    </div>
  );
}
